import React from "react"
import Layout from "../components/App/Layout"
import NavbarTwo from "../components/App/NavbarTwo"
import Footer from "../components/App/Footer"
import MainBanner from "../components/DigitalMarketingAgency/MainBanner"
import Services from "../components/DigitalMarketingAgency/Services"


const Index = () => {
    return (
        <Layout>
            <NavbarTwo />
            <MainBanner />
           
            <Services />
           
            <Footer />
        </Layout>
    );
}

export default Index